import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Grid } from '@mui/material';
import { BrandQuestionsInboundCommentInsights } from 'features/brandCompetitors';
import {
  cleanSLAAnalyticsFilterValues,
  SLAAnalyticsFilters,
  SLAAnalyticsSelectedFilterItem,
  SLAAnalyticsSelectedFilterItemType,
  SLABrandContentTypeSelector,
  SLABrandInboundCreatorDemographicsView,
  SLABrandInboundGraphView,
  SLABrandInboundRankingView,
  SLABrandInboundSentimentsContainerView,
  SLABrandInboundSummaryView,
  SLABrandInboundTopHashtagsView,
  SLABrandInboundTopPostsView,
} from 'features/socialListeningAnalytics';
import { SLALoadingState } from 'features/socialListeningAnalytics/components/slaLoadingState';
import { SOCIAL_LISTENING_ANALYTICS_BRAND_FILTERS } from 'features/socialListeningAnalytics/constants';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  ListeningTopicType,
  SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragment,
  useGetBrandForSlaBrandAnalyticsInboundViewQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { SLABrandInboundTopCreatorsView } from '../slaBrandInboundTopCreators';
import { SLABrandTopCreatorPostsView } from '../slaBrandTopCreatorPosts';

type Props = {
  brandId: string;
};

export const SLABrandAnalyticsInboundView = ({ brandId }: Props) => {
  const [selectedCreatorId, setSelectedCreatorId] = useState('');
  const { value: _selectedFilters, setValue: setSelectedFilters } =
    useLocalStorage<Partial<BrandInboundFiltersInput>>(
      SOCIAL_LISTENING_ANALYTICS_BRAND_FILTERS + brandId,
      {},
    );

  const selectedFilters = useMemo(() => {
    return _selectedFilters as BrandInboundFiltersInput;
  }, [_selectedFilters]);
  const topCreatorPostsDisclosure = useDisclosure();

  const { data: brandData, loading: brandLoading } =
    useGetBrandForSlaBrandAnalyticsInboundViewQuery({
      variables: { brandId },
      skip: !brandId,
    });
  const brand = brandData?.brand;
  const brandMentionedTopicId = brand?.topics?.find(
    (topic) => topic.type === ListeningTopicType.BrandMention,
  )?.id;

  const [currentTopicCategories, setCurrentTopicCategories] = useState<
    SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragment[]
  >([]);

  const updateFilters = useCallback(
    (newFilters: Partial<BrandInboundFiltersInput>) => {
      setSelectedFilters((prevFilters) => {
        const updatedFilters = {
          ...prevFilters,
          ...newFilters,
          timezone: moment.tz.guess(),
        };
        if (JSON.stringify(updatedFilters) !== JSON.stringify(prevFilters)) {
          return updatedFilters;
        }
        return prevFilters;
      });
    },
    [setSelectedFilters],
  );

  useEffect(() => {
    if (selectedFilters.contentType === BrandContentType.Owned) {
      updateFilters({
        signalDefinitionIds: [],
        signalCategoryOptionIds: [],
      });
    }
  }, [selectedFilters.contentType]); // eslint-disable-line

  const renderSelectedFilters = useMemo(() => {
    const selectedFilterTypes: {
      type: SLAAnalyticsSelectedFilterItemType;
      length: number;
    }[] = [
      {
        type: 'category',
        length:
          selectedFilters.contentType === BrandContentType.Ugc
            ? (selectedFilters.signalDefinitionIds?.length ||
                selectedFilters.signalCategoryOptionIds?.length) ??
              0
            : 0,
      },
      {
        type: 'demographic',
        length:
          (selectedFilters.gender?.length ||
            selectedFilters.generation?.length) ??
          0,
      },
      { type: 'source', length: selectedFilters.sources?.length ?? 0 },
      {
        type: 'capturedProductBrand',
        length: selectedFilters.capturedProductBrandIds?.length ?? 0,
      },
      {
        type: 'capturedProductProductLineModel',
        length: selectedFilters.capturedProductLineModelInputs?.length ?? 0,
      },
      {
        type: 'capturedProductAttribute',
        length: selectedFilters.capturedProductAttributeIds?.length ?? 0,
      },
      {
        type: 'capturedProductCategory',
        length: selectedFilters.capturedProductCategoryIds?.length ?? 0,
      },
      {
        type: 'languages',
        length: selectedFilters.languages?.length ?? 0,
      },
    ];

    if (!selectedFilterTypes.some((filter) => filter.length)) {
      return null;
    }
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {selectedFilterTypes.map(({ type, length }) => {
          if (!length) return null;

          return (
            <SLAAnalyticsSelectedFilterItem
              key={type}
              selectedFilters={selectedFilters}
              onChange={(filters) => updateFilters(filters)}
              type={type}
              {...(type === 'category' && {
                signalDefinitions: currentTopicCategories,
              })}
            />
          );
        })}
      </Box>
    );
  }, [selectedFilters, updateFilters]);

  const brandInboundFilters = useMemo(() => {
    return {
      ...cleanSLAAnalyticsFilterValues(selectedFilters),
      brandId,
      topicIds:
        selectedFilters?.contentType === BrandContentType.Ugc
          ? [brandMentionedTopicId]
          : undefined,
      contentType: selectedFilters?.contentType ?? BrandContentType.Owned,
      dateRange: {
        endDate: selectedFilters.dateRange?.endDate || moment().toDate(),
        startDate:
          selectedFilters.dateRange?.startDate ||
          moment().subtract(7, 'day').startOf('day').toDate(),
      },
    } as BrandInboundFiltersInput;
  }, [brandId, selectedFilters]);

  if (brandLoading) {
    return (
      <Box
        height="calc(100vh - 200px)"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <SLALoadingState />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          py: 4,
          my: 2,
          borderBottom: `1px solid ${theme.colors?.utility[300]}`,
          borderTop: `1px solid ${theme.colors?.utility[300]}`,
          position: 'sticky',
          top: 64,
          zIndex: 10,
          backgroundColor: theme.colors?.primary.white,
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {brand && (
            <SLABrandContentTypeSelector
              brand={brand}
              contentType={
                brandInboundFilters?.contentType ?? BrandContentType.Owned
              }
              onChange={(value) => {
                updateFilters({
                  contentType: value,
                });
              }}
            />
          )}
          <SLAAnalyticsFilters
            brandId={brandId}
            topicId={
              brandInboundFilters.contentType === BrandContentType.Ugc
                ? brandMentionedTopicId
                : undefined
            }
            hideOptions={{
              category:
                brandInboundFilters.contentType === BrandContentType.Owned,
            }}
            contentType={brandInboundFilters.contentType}
            onChange={updateFilters}
            selectedFilters={brandInboundFilters}
            onSetCurrentTopicCategories={setCurrentTopicCategories}
          />
        </Box>
        {renderSelectedFilters}
      </Box>

      <Grid mt={2} container spacing={6} columns={12}>
        <Grid item xs={12} lg={4}>
          <Box
            display="flex"
            flexDirection="column"
            gap={6}
            width="100%"
            flex={1}
            height="100%"
          >
            <SLABrandInboundSummaryView filters={brandInboundFilters} />
            {brand && (
              <SLABrandInboundTopHashtagsView
                brand={brand}
                filters={brandInboundFilters}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <SLABrandInboundTopPostsView filters={brandInboundFilters} />
        </Grid>
      </Grid>

      {brandInboundFilters.contentType === BrandContentType.Ugc && (
        <Box
          my={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <SLABrandInboundTopCreatorsView
            filters={brandInboundFilters}
            onSelectCreator={(creatorId) => {
              setSelectedCreatorId(creatorId);
              topCreatorPostsDisclosure.onOpen();
            }}
          />
          <SLABrandInboundCreatorDemographicsView
            filters={brandInboundFilters}
            onChange={({ selectedGender, selectedGeneration }) => {
              updateFilters({
                gender: selectedGender,
                generation: selectedGeneration,
              });
            }}
          />
        </Box>
      )}

      <Box my={6}>
        <SLABrandInboundRankingView
          filters={brandInboundFilters}
          onSelectFilter={updateFilters}
        />
      </Box>

      <Box my={6}>
        <SLABrandInboundGraphView filters={brandInboundFilters} />
      </Box>

      {selectedCreatorId && brand && topCreatorPostsDisclosure.isOpen && (
        <SLABrandTopCreatorPostsView
          brand={brand}
          filters={brandInboundFilters}
          creatorId={selectedCreatorId}
          isOpen={topCreatorPostsDisclosure.isOpen}
          onClose={topCreatorPostsDisclosure.onClose}
          defaultDateRange={{
            startDate: selectedFilters.dateRange?.startDate,
            endDate: selectedFilters.dateRange?.endDate,
          }}
        />
      )}

      <SLABrandInboundSentimentsContainerView
        filters={brandInboundFilters}
        hideInPosts={brandInboundFilters.contentType === BrandContentType.Owned}
      />

      <BrandQuestionsInboundCommentInsights filters={brandInboundFilters} />
    </Box>
  );
};
